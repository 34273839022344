import { Button, Paper, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import Loader from "../../../components/ui/Loader";
import { V1 } from "../../../utils/api";
import styled from "@emotion/styled";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DropZone from "../../../components/Dropzone";
import { Chip } from "@mui/material";

import { useFormik } from "formik";
import { createRequest } from "../../../utils/validations";
import { useParams } from "react-router-dom";

const ThankYouSVG = (props) => (
  <svg
    width={199}
    height={199}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={99.5} cy={99.5} r={99.5} fill="#E30522" fillOpacity={0.1} />
    <circle cx={99.5} cy={99.5} r={64.5} fill="#E30522" fillOpacity={0.1} />
    <circle cx={99.5} cy={99.5} r={37.5} fill="#E30522" fillOpacity={0.1} />
    <path
      d="M92.859 113h-4.115a2.768 2.768 0 0 1-1.94-.791A2.677 2.677 0 0 1 86 110.3v-9.45c0-.716.289-1.403.804-1.91a2.766 2.766 0 0 1 1.94-.79h4.115m9.602-2.7v-5.4a4.016 4.016 0 0 0-1.205-2.864A4.15 4.15 0 0 0 98.346 86l-5.487 12.15V113h15.473a2.77 2.77 0 0 0 1.808-.643 2.686 2.686 0 0 0 .936-1.652l1.893-12.15a2.687 2.687 0 0 0-.642-2.177 2.739 2.739 0 0 0-.947-.692 2.783 2.783 0 0 0-1.155-.236h-7.764Z"
      stroke="#E30522"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MainWrapper = styled.div`
  padding: 30px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled(Paper)`
  max-width: 600px;
  margin: auto;
`;
export default function Dashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      name: "",
      client: [],
      form: id,
      phone: "",
      message: "",
      files: [],
    },
    enableReinitialize: true,
    validationSchema: createRequest,
    onSubmit: (values) => {
      setLoading(true);
      setSubmitted(false);
      V1.post("/requests", values)
        .then((res) => {
          const formData = new FormData();
          values.files.map((el) => {
            formData.append("files", el);
          });
          V1.put(`/requests/${res.data.data._id}/files`, formData)
            .then((res) => {
              setSubmitted(true);
              setLoading(false);
            })
            .catch((err) => {
              setSubmitted(false);
              setLoading(false);
            });
        })
        .catch((err) => {
          setSubmitted(false);
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (id) {
      V1.get(`/forms/info/${id}`)
        .then((res) => {
          setData(res.data.data);
          if (res.headers["request-duration"] > 300) {
            setLoading(false);
          } else {
            setTimeout(() => {
              setLoading(false);
            }, 300);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
        });
    }
  }, [id]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div>Etwas ist schief gelaufen</div>;
  }
  if (submitted) {
    return (
      <MainWrapper>
        <Wrapper>
          <div
            style={{
              borderBottom: "1px solid #ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 30,
            }}
          >
            <img src="/images/logo.png" style={{ textAlign: "center" }} />
          </div>
          <div style={{ padding: 30 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "40px 0",
              }}
            >
              <ThankYouSVG />
              <h1 style={{ textAlign: "center", margin: 0, marginTop: 30 }}>
                Vielen Dank
              </h1>
              <p style={{ margin: 0, marginTop: 15, textAlign: "center" }}>
                Ihr Formular wurde gesendet, wir werden Sie innerhalb von 24
                Stunden kontaktieren
              </p>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ marginTop: 30 }}
              onClick={(e) => setSubmitted(false)}
            >
              Biete noch eins an
            </Button>
          </div>
        </Wrapper>
      </MainWrapper>
    );
  }
  return (
    <MainWrapper>
      <Wrapper>
        <form onSubmit={formik.handleSubmit}>
          <div
            style={{
              borderBottom: "1px solid #ccc",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 30,
            }}
          >
            <img src="/images/logo.png" style={{ textAlign: "center" }} />
          </div>
          <div style={{ padding: 30 }}>
            <FormControl fullWidth>
              <InputLabel id="client">Kunden</InputLabel>
              <Select
                labelId="client"
                id="client"
                label="Kunden"
                name="client"
                value={formik.values.client}
                onChange={formik.handleChange}
                error={formik.touched.client && Boolean(formik.errors.client)}
              >
                {data?.clients.map((client) => (
                  <MenuItem value={client}>{client}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Vorname und Nachname"
              fullWidth
              margin="normal"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              label="Telefonnummer"
              fullWidth
              margin="normal"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <TextField
              label="Beschreibung"
              multiline
              rows={6}
              fullWidth
              margin="normal"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
            {console.log(formik.values)}
            <div style={{ paddingBottom: 15 }}>
              {formik.values.files.map((el) => (
                <Chip
                  style={{ width: "100%", marginTop: 15 }}
                  // sx={{ width: "100%", marginTop: 15 }}
                  label={el.name}
                  onDelete={() => {
                    let tmp = [...formik.values.files];
                    tmp = tmp.filter((fil) => fil.name != el.name);
                    formik.setFieldValue("files", tmp);
                  }}
                />
              ))}
            </div>

            <DropZone
              setFiles={(files) => {
                formik.setFieldValue("files", files);
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ marginTop: 30 }}
            >
              Einreichen
            </Button>
          </div>
        </form>
      </Wrapper>
    </MainWrapper>
  );
}
