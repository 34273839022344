import { Paper, Grid } from "@mui/material";
import Layout from "../../components/Layout";
import Head from "../../components/Page/Head";
import { V1 } from "../../utils/api";
import { useEffect, useState } from "react";
import Loader from "../../components/ui/Loader";

export default function Dashboard() {
  const [forms, setForms] = useState(0);
  const [requests, setRequests] = useState(0);
  const [codes, setCodes] = useState(0);
  const [completedRequests, setCompletedRequests] = useState(0);
  const [unCompletedRequests, setUnCompletedRequests] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    V1.get("/analytics").then((res) => {
      setForms(res.data.data?.forms);
      setRequests(res.data.data?.requests);
      setCodes(res.data.data?.codes);
      setCompletedRequests(res.data.data?.completedRequests);
      setUnCompletedRequests(res.data.data?.unCompletedRequests);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Head title="Dashboard" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper style={{ textAlign: "center" }}>
            <h1>{forms} </h1>
            <h1>Total forms</h1>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ textAlign: "center" }}>
            <h1>{requests}</h1>
            <h1>Total requests</h1>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ textAlign: "center" }}>
            <h1>{completedRequests}</h1>
            <h1>Total Completed Requests</h1>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ textAlign: "center" }}>
            <h1>{unCompletedRequests}</h1>
            <h1>Total Uncompleted Requests</h1>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ textAlign: "center" }}>
            <h1>{codes}</h1>
            <h1>Total Scans</h1>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

Dashboard.Layout = Layout;
