import CssBaseline from "@mui/material/CssBaseline";
// import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/ui/theme";
import store from "./redux/store";
import { Provider } from "react-redux";
import Router from "./components/Router";
import { SnackbarProvider } from "notistack";
// import { ConfirmProvider } from "./components/Confirm";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ThemeProvider from "./components/ui/ThemeProvider";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          {/* <ConfirmProvider> */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Router />
          </LocalizationProvider>
          {/* </ConfirmProvider> */}
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
