import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Paper, Button, TextField, Grid } from "@mui/material";
import { useFormik } from "formik";
import { updatePersonalInfo } from "../../utils/validations";
import { useSelector, useDispatch } from "react-redux";
import { V1 } from "../../utils/api";
import Alert from "@mui/material/Alert";
import Loader from "../../components/ui/Loader";
import { useSnackbar } from "notistack";
import Head from "../../components/Page/Head";

const Wrapper = styled.div`
  > .MuiPaper-root {
    padding: 30px;
    width: 100%;
    max-width: 600px;
    position: relative;
    overflow: hidden;
    h1 {
      margin-bottom: 0;
      margin-top: 0;
      font-weight: 900;
    }
    > p {
      margin-bottom: 30px;
      margin-top: 0;
      color: #464646;
    }
  }
`;

import Layout from "../../components/Layout";

export default function Security() {
  const { user } = useSelector((state) => state.auth);
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [updateUserError, setUpdateUserError] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      email: user?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
    },
    validationSchema: updatePersonalInfo,
    onSubmit: (values) => {
      setUpdateUserLoading(true);
      setUpdateUserError(false);
      V1.put("/auth/user", values)
        .then((res) => {
          setUpdateUserLoading(false);
          setUpdateUserError(false);

          enqueueSnackbar("Profile info updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        })
        .catch((err) => {
          setUpdateUserLoading(false);
          setUpdateUserError(err.response.data.error);
        });
    },
  });

  return (
    <Wrapper>
      <Head title="Persönliche Informationen" />
      <Paper>
        {updateUserLoading && <Loader />}
        {updateUserError && <Alert severity="error">{updateUserError}</Alert>}
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Vorname"
                variant="outlined"
                fullWidth
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nachname"
                variant="outlined"
                fullWidth
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="E-mail"
                variant="outlined"
                fullWidth
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Telefon"
                variant="outlined"
                fullWidth
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            fullWidth
            style={{ marginTop: 15 }}
            type="submit"
          >
            Änderungen speichern
          </Button>
        </form>
      </Paper>
    </Wrapper>
  );
}

Security.Layout = Layout;
