import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AuthProvider from "../providers/AuthProvider";

const SidebarWrapper = styled.div`
  z-index: 1000 !important;
  border-right: 1px solid ${(props) => props.theme.palette.divider};
  padding: 0 15px;
  background: linear-gradient(180deg, #ac0017 0%, #7f0011 84.79%, #5c000c 100%);
  height: 100vh;
  width: ${(props) => (props?.open ? "300px" : "90px")};
  transition: 0.3s ease-in-out;
  position: fixed;
  left: 0;
  top: 0;

  .MuiListItemText-root {
    display: ${(props) => (props?.open ? "block" : "none")};
    width: 142px !important;
    position: relative;
    overflow: hidden;

    .MuiTypography-root {
      width: 142px !important;
      position: relative;
      overflow: hidden;
    }
  }
  .MuiListItem-root {
    margin-bottom: 10px;
    .MuiListItemButton-root {
      > .MuiSvgIcon-root {
        display: ${(props) => (props?.open ? "block" : "none")};
      }
    }
  }
  a,
  .MuiListItemButton-root {
    color: #fff;
    text-decoration: none !important;
    border-radius: 5px;
    height: 59px;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      color: #fff;
    }

    &.active,
    &:hover {
      background-color: ${(props) => alpha("#fff", 0.2)};
    }

    &:hover {
      text-decoration: none !important;
    }
  }
`;

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
const ToggleWrapper = styled.div`
  align-items: center;
  height: 80px;
  display: flex;
  button {
    width: 59px;
    height: 59px;
    border-radius: 5px;

    &:hover {
      background-color: ${(props) => alpha("#fff", 0.2)};
    }
  }
  svg {
    color: #fff;
  }
`;

export default function Sidebar({
  open,
  setOpen,
  sidebarOpen,
  setSidebarOpen,
}) {
  const handleClick = () => {
    setOpen(!open);
  };
  const isInsideSettings = false;

  return (
    <SidebarWrapper open={sidebarOpen}>
      <AuthProvider>
        <ToggleWrapper>
          <IconButton
            onClick={(e) => {
              localStorage.setItem("sidebar", `${!sidebarOpen}`);
              setSidebarOpen(!sidebarOpen);
            }}
          >
            {sidebarOpen ? (
              <CloseOutlinedIcon style={{ width: 32, height: 32 }} />
            ) : (
              <MenuOutlinedIcon style={{ width: 32, height: 32 }} />
            )}
          </IconButton>
        </ToggleWrapper>
        <List>
          <ListItem disablePadding>
            <ListItemButton component={NavLink} to="/dashboard">
              <ListItemIcon>
                <DashboardOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={NavLink} to="/requests">
              <ListItemIcon>
                <ArticleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Anfragen" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={NavLink} to="/forms">
              <ListItemIcon>
                <ArticleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Formen" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              onClick={handleClick}
              className={`${open || isInsideSettings ? "active" : ""}`}
            >
              <ListItemIcon>
                <SettingsOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Einstellungen" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/account">
                  <ListItemIcon>
                    <PersonOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Persönliche Informationen" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/account/security">
                  <ListItemIcon>
                    <ShieldOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Passwort" />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>

          <ListItem disablePadding>
            <ListItemButton component={NavLink} to="/logout">
              <ListItemIcon>
                <LogoutOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Abmelden" />
            </ListItemButton>
          </ListItem>
        </List>
      </AuthProvider>
    </SidebarWrapper>
  );
}
