import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import { canDrag, canDrop } from "./utils";

import RemoveComponent from "./RemoveComponent";
import styled from "@emotion/styled";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
const ItemTypes = { TAG: "tag" };

const TabItem = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 12px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    svg {
      opacity: 0.6;
    }
    > span {
      margin-left: 12px;
    }
  }
`;

const Tag = (props) => {
  const tagRef = useRef(null);
  const { readOnly, tag, classNames, index } = props;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.TAG,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: props,
    canDrag: () => canDrag(props),
  }));

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.TAG,
    drop: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      props.moveTag(dragIndex, hoverIndex);
    },
    canDrop: (item) => canDrop(item),
  }));

  drag(drop(tagRef));

  const label = props.tag[props.labelField];
  const { className = "" } = tag;
  /* istanbul ignore next */
  const opacity = isDragging ? 0 : 1;
  const tagComponent = (
    <TabItem
      ref={tagRef}
      className={ClassNames("tag-wrapper", classNames.tag, className)}
      style={{
        opacity,
        cursor: canDrag(props) ? "move" : "auto",
      }}
      onClick={props.onTagClicked}
      onTouchStart={props.onTagClicked}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <DragIndicatorIcon />
        <span>{label}</span>
      </div>

      <RemoveComponent
        tag={props.tag}
        className={classNames.remove}
        removeComponent={props.removeComponent}
        onRemove={props.onDelete}
        readOnly={readOnly}
        index={index}
      />
    </TabItem>
  );
  return tagComponent;
};

Tag.propTypes = {
  labelField: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    key: PropTypes.string,
  }),
  moveTag: PropTypes.func,
  removeComponent: PropTypes.func,
  onTagClicked: PropTypes.func,
  classNames: PropTypes.object,
  readOnly: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

Tag.defaultProps = {
  labelField: "text",
  readOnly: false,
};

export default Tag;
