import React, { useState } from "react";
import styled from "@emotion/styled";
import { Paper, Button, TextField, Grid } from "@mui/material";
import { useFormik } from "formik";
import { changePasswordSchema } from "../../utils/validations";
import { V1 } from "../../utils/api";
import Alert from "@mui/material/Alert";
import Loader from "../../components/ui/Loader";
import { useSnackbar } from "notistack";
import Head from "../../components/Page/Head";
import Layout from "../../components/Layout";

const Wrapper = styled.div`
  > .MuiPaper-root {
    padding: 30px;
    width: 100%;
    max-width: 600px;
    position: relative;
    overflow: hidden;
    h1 {
      margin-bottom: 0;
      margin-top: 0;
      font-weight: 900;
    }
    > p {
      margin-bottom: 30px;
      margin-top: 0;
      color: #464646;
    }
  }
`;

export default function Security() {
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const changePasswordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      setChangePasswordLoading(true);
      V1.post("/auth/change-password", values)
        .then((res) => {
          setChangePasswordLoading(false);
          setChangePasswordError(null);
          enqueueSnackbar("Passwort wurde aktualisiert", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        })
        .catch((err) => {
          setChangePasswordLoading(false);
          setChangePasswordError(err.response.data.error);
        });
    },
  });

  return (
    <Wrapper>
      <Head title="Passwort" />
      <Paper>
        {changePasswordLoading && <Loader />}
        {changePasswordError && (
          <Alert style={{ marginBottom: 15 }} severity="error">
            {changePasswordError}
          </Alert>
        )}
        <form onSubmit={changePasswordFormik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                label="Altes Passwort"
                variant="outlined"
                fullWidth
                type="password"
                name="oldPassword"
                value={changePasswordFormik.values.oldPassword}
                onChange={changePasswordFormik.handleChange}
                error={
                  changePasswordFormik.touched.oldPassword &&
                  Boolean(changePasswordFormik.errors.oldPassword)
                }
                helperText={
                  changePasswordFormik.touched.oldPassword &&
                  changePasswordFormik.errors.oldPassword
                }
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                label="Neues Passwort"
                variant="outlined"
                fullWidth
                type="password"
                name="newPassword"
                value={changePasswordFormik.values.newPassword}
                onChange={changePasswordFormik.handleChange}
                error={
                  changePasswordFormik.touched.newPassword &&
                  Boolean(changePasswordFormik.errors.newPassword)
                }
                helperText={
                  changePasswordFormik.touched.newPassword &&
                  changePasswordFormik.errors.newPassword
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Bestätige neues Passwort"
                variant="outlined"
                fullWidth
                type="password"
                name="confirmNewPassword"
                value={changePasswordFormik.values.confirmNewPassword}
                onChange={changePasswordFormik.handleChange}
                error={
                  changePasswordFormik.touched.confirmNewPassword &&
                  Boolean(changePasswordFormik.errors.confirmNewPassword)
                }
                helperText={
                  changePasswordFormik.touched.confirmNewPassword &&
                  changePasswordFormik.errors.confirmNewPassword
                }
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            fullWidth
            style={{ marginTop: 15 }}
            type="submit"
          >
            Einreichen
          </Button>
        </form>
      </Paper>
    </Wrapper>
  );
}

Security.Layout = Layout;
