import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";

const Wrapper = styled.div`
  position: ${({ type }) => type};
  inset: 0;
  background-color: ${({ opacity }) => `rgba(255,255,255,${opacity})`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Loader({ opacity = 1, type = "absolute" }) {
  return (
    <Wrapper type={type} opacity={opacity}>
      <CircularProgress />
    </Wrapper>
  );
}
