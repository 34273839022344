import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import Loader from "../../components/ui/Loader";
import { axios } from "../../utils/api";
import Layout from "../../components/Layout";
import Head from "../../components/Page/Head";
import TableComp from "../../components/Requests/Table";

export default function Dashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    axios
      .get("/requests")
      .then((res) => {
        setData(res.data.data);
        if (res.headers["request-duration"] > 300) {
          setLoading(false);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 300);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  }, []);

  const deleteRow = (id) => {
    setLoading(true);
    axios
      .delete(`/requests/${id}`)
      .then((res) => {
        const tmp = data.filter((el) => el?._id != id);
        setData(tmp);
        if (res.headers["request-duration"] > 300) {
          setLoading(false);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 300);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div>Something went wrong</div>;
  }
  return (
    <>
      <Head title="Anfragen" />
      <TableComp rows={data} deleteRow={deleteRow} />
    </>
  );
}

Dashboard.Layout = Layout;
