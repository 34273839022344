import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Switch from "@mui/material/Switch";
import { useSnackbar } from "notistack";
import { V1 } from "../../utils/api";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

function Completed({ item }) {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState(item.completed);

  const updateCompleted = (checked) => {
    V1.put(`/requests/${item._id}`, {
      completed: checked,
    }).then((res) => {
      enqueueSnackbar(`Anfrage aktualisiert`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    });
  };
  return (
    <Switch
      checked={value}
      onChange={(e) => {
        setValue(e.target.checked);
        updateCompleted(e.target.checked);
      }}
    />
  );
}

export default function TableComp({ rows, deleteRow }) {
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  console.log(rows);
  return (
    <>
      <TableContainer component={Paper}>
        {rows.length === 0 ? (
          <p style={{ textAlign: "center", width: "100%" }}>Keine Ergebnisse</p>
        ) : (
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Kunde</TableCell>
                <TableCell>Antragssteller/in</TableCell>
                <TableCell>Telefonnummer</TableCell>
                <TableCell>Meldung</TableCell>
                <TableCell align="center">Files</TableCell>
                <TableCell align="right">Abgeschlossen</TableCell>

                <TableCell align="right">Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row.client}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell>{row.message}</TableCell>
                  <TableCell align="center">
                    {row.files && row.files.length > 0 ? (
                      <Button
                        onClick={() => {
                          setOpen(true);
                          setFiles(row.files);
                          console.log(row.files);
                        }}
                      >
                        Dokumente
                      </Button>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Completed item={row} />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => deleteRow(row._id)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Files</DialogTitle>
        <DialogContent dividers style={{ padding: 0, minWidth: 500 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files
                .filter((el) => el.url)
                .map((row, index) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      File {index + 1}
                    </TableCell>

                    <TableCell align="right">
                      <IconButton
                        onClick={() => {
                          window.open(row.url, "_blank");
                        }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            {" "}
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
