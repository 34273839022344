import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import LinearLoader from "../components/ui/LinearLoader";
import Loader from "../components/ui/Loader";
import Alert from "@mui/material/Alert";
import { V1 } from "../utils/api";
import { useDispatch, useSelector, use } from "react-redux";
import { loginValidationSchema } from "../utils/validations";
import { Paper, Button, TextField } from "@mui/material";
import { useFormik } from "formik";

import { useNavigate } from "react-router";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const CardWrapper = styled(Paper)`
  max-width: 600px;
  width: 100%;
  padding: 30px;
  position: relative;
  overflow: hidden;
`;

export default function Login() {
  const { loading, error, isAuthenticated } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      dispatch({
        type: "SET_USER_LOADING",
        payload: true,
      });
      V1.post("/auth/login", values)
        .then((res) => {
          dispatch({
            type: "SET_USER",
            payload: res.data.data,
            token: res.data.token,
          });

          console.log(res.data);
          navigate("/dashboard");
        })
        .catch((err) => {
          dispatch({
            type: "SET_ERROR",
            payload: err.response.data.error,
          });
          dispatch({
            type: "SET_USER_LOADING",
            payload: false,
          });
        });
    },
  });
  if (isAuthenticated) return <Loader type="fixed" />;
  return (
    <Wrapper>
      <LinearLoader loading={loading} />
      <CardWrapper>
        {loading && <Loader />}
        <h1>Login</h1>
        <p>Please enter your details</p>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button
            variant="contained"
            fullWidth
            style={{ marginTop: 15 }}
            type="submit"
          >
            Login
          </Button>
        </form>
      </CardWrapper>
    </Wrapper>
  );
}
