import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, TextField, Grid } from "@mui/material";
import { useFormik } from "formik";
import { createForm } from "../../utils/validations";
import { V1 } from "../../utils/api";
import Alert from "@mui/material/Alert";
import Loader from "../../components/ui/Loader";
import { useSnackbar } from "notistack";
import Head from "../../components/Page/Head";
import Layout from "../../components/Layout";

import { ManageClients } from "../../components/Forms/ManageClients";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  max-width: 1024px;
  > .MuiPaper-root {
    padding: 30px;
    width: 100%;
    max-width: 600px;
    position: relative;
    overflow: hidden;
    h1 {
      margin-bottom: 0;
      margin-top: 0;
      font-weight: 900;
    }
    > p {
      margin-bottom: 30px;
      margin-top: 0;
      color: #464646;
    }
  }
`;

export default function Security() {
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [updateUserError, setUpdateUserError] = useState(false);
  const [tags, setTags] = useState([]);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      name: "",
      clients: [],
    },
    validationSchema: createForm,
    onSubmit: (values) => {
      setUpdateUserLoading(true);
      setUpdateUserError(false);
      V1.post("/forms", {
        name: values.name,
        clients: tags.map((tag) => tag.text),
      })
        .then((res) => {
          navigate("/forms");
          enqueueSnackbar("From created", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
          setUpdateUserLoading(false);
          setUpdateUserError(false);
        })
        .catch((err) => {
          setUpdateUserLoading(false);
          setUpdateUserError(err.response.data.error);
        });
    },
  });
  if (updateUserLoading) {
    return <Loader />;
  }
  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        {updateUserError && <Alert severity="error">{updateUserError}</Alert>}
        <Head title="Create form">
          <Button variant="contained" color="primary" type="submit">
            Create
          </Button>
        </Head>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ManageClients tags={tags} setTags={setTags} />
          </Grid>
        </Grid>
      </form>
    </Wrapper>
  );
}

Security.Layout = Layout;
