import React from "react";
import Sidebar from "./Sidebar";
import styled from "styled-components";
// import Authentication from "../wrappers/Authentication";

const Wrapper = styled.div`
  display: flex;
`;

const Main = styled.div`
  width: 100%;
  margin-left: ${(props) => (props.open ? `300px` : `90px`)};
  transition: 0.3s ease-in-out;
`;
const Container = styled.div`
  padding: 0 30px;
  position: relative;
  min-height: 100vh;
`;

export default function Layout({ children }) {
  const [open, setOpen] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(
    JSON.parse(localStorage.getItem("sidebar") || "false")
  );

  return (
    <Wrapper>
      <Sidebar
        open={open}
        setOpen={setOpen}
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
      />
      <Main open={sidebarOpen}>
        <Container>{children}</Container>
      </Main>
    </Wrapper>
  );
}
