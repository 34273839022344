import React from "react";
import Dropzone from "react-dropzone";
import styled from "@emotion/styled";

const DropZoneWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 200px;
  border: 3px dashed #eeeeee;
  border-radius: 12px;
  background-color: #fafafa;
  color: #bdbdbd;

  p {
    margin: 0;
    text-align: center;
  }
`;

function index(props) {
  const { setFiles } = props;
  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  return (
    <div className="App">
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <DropZoneWrapper {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Legen Sie Ihre Dateien hier ab</p>
            ) : (
              <p>
                Ziehen Sie Dateien per Drag-and-Drop oder klicken Sie, um
                Dateien auszuwählen
              </p>
            )}
          </DropZoneWrapper>
        )}
      </Dropzone>
    </div>
  );
}

export default index;
