import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Paper, Button, TextField, Grid } from "@mui/material";
import { useFormik } from "formik";
import { createForm } from "../../../utils/validations";
import { useSelector, useDispatch } from "react-redux";
import { V1 } from "../../../utils/api";
import Alert from "@mui/material/Alert";
import Loader from "../../../components/ui/Loader";
import { useSnackbar } from "notistack";
import Head from "../../../components/Page/Head";
import Layout from "../../../components/Layout";
// import Router from "next/router";
import { ManageClients } from "../../../components/Forms/ManageClients";
// import { useRouter } from "next/router";
import { useParams } from "react-router-dom";
const Wrapper = styled.div`
  max-width: 1024px;
  > .MuiPaper-root {
    padding: 30px;
    width: 100%;
    max-width: 600px;
    position: relative;
    overflow: hidden;
    h1 {
      margin-bottom: 0;
      margin-top: 0;
      font-weight: 900;
    }
    > p {
      margin-bottom: 30px;
      margin-top: 0;
      color: #464646;
    }
  }
`;

export default function Security() {
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [updateUserError, setUpdateUserError] = useState(false);
  const [tags, setTags] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      V1.get(`/forms/info/${id}`)
        .then((res) => {
          setData(res.data.data);
          setTags(
            res.data.data.clients.map((client) => {
              return {
                id: client,
                text: client,
              };
            })
          );
          if (res.headers["request-duration"] > 300) {
            setLoading(false);
          } else {
            setTimeout(() => {
              setLoading(false);
            }, 300);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(true);
        });
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      name: data?.name,
    },
    validationSchema: createForm,
    enableReinitialize: true,
    onSubmit: (values) => {
      setUpdateUserLoading(true);
      setUpdateUserError(false);
      V1.put(`/forms/info/${id}`, {
        name: values.name,
        clients: tags.map((tag) => tag.text),
      })
        .then((res) => {
          enqueueSnackbar("Form info updated", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
          setUpdateUserLoading(false);
          setUpdateUserError(false);
        })
        .catch((err) => {
          setUpdateUserLoading(false);
          setUpdateUserError(err.response.data.error);
        });
    },
  });
  if (updateUserLoading) {
    return <Loader />;
  }
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div>Something went wrong</div>;
  }
  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        {updateUserError && <Alert severity="error">{updateUserError}</Alert>}
        <Head title="Update form">
          <Button variant="contained" color="primary" type="submit">
            Update
          </Button>
        </Head>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <ManageClients tags={tags} setTags={setTags} />
          </Grid>
        </Grid>
      </form>
    </Wrapper>
  );
}

Security.Layout = Layout;
