import { Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Loader from "../../components/ui/Loader";
import { axios } from "../../utils/api";
import Layout from "../../components/Layout";
import Head from "../../components/Page/Head";
import Tabs from "../../components/QR/Single/Tabs";
import { useParams } from "react-router-dom";

export default function Dashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/forms/info/${id}`)
      .then((res) => {
        setData(res.data.data);
        if (res.headers["request-duration"] > 300) {
          setLoading(false);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 300);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  }, []);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <div>Something went wrong</div>;
  }
  return (
    <>
      <Head title={`My QR codes > ${data.name}`}>
        <Button variant="contained">Update</Button>
      </Head>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Tabs />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
    </>
  );
}

Dashboard.Layout = Layout;
