import React, { useEffect } from "react";
import styled from "styled-components";
import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
const Wrapper = styled.div`
  padding: 60px;
  align-items: center;
  display: flex;
  height: calc(100vh - 64px);
  img {
    width: 100%;
  }
  h1 {
    font-size: 240px;
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 70px;
    margin-bottom: 30px;
  }
`;
export default function Error() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_RANDOM",
      payload: { pageTitle: "", button: null },
    });
  }, []);
  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <h1>404</h1>
          <p>
            Something went <br />
            <strong>WRONG!</strong>
          </p>

          <Button component={Link} to="/dashboard" variant="contained">
            Dashboard
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src="/images/error.png" alt="" />
        </Grid>
      </Grid>
    </Wrapper>
  );
}
