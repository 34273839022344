const axiosRequest = require("axios");
import { API, API_ROOT } from "./config";

export const V1 = axiosRequest.create({
  baseURL: API,
});

// Request interceptor for API calls
V1.interceptors.request.use(
  async (config) => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers = {
          Authorization: `Bearer ${token}`,
          "x-auth-token": token,
        };
      }
    }
    config.headers["request-startTime"] = new Date().getTime();
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

V1.interceptors.response.use((response) => {
  const currentTime = new Date().getTime();
  const startTime = response.config.headers["request-startTime"];
  response.headers["request-duration"] = currentTime - startTime;
  return response;
});

export const axios = axiosRequest.create({
  baseURL: API_ROOT,
});

// Request interceptor for API calls
axios.interceptors.request.use(
  async (config) => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers = {
          Authorization: `Bearer ${token}`,
          "x-auth-token": token,
        };
      }
    }
    config.headers["request-startTime"] = new Date().getTime();
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use((response) => {
  const currentTime = new Date().getTime();
  const startTime = response.config.headers["request-startTime"];
  response.headers["request-duration"] = currentTime - startTime;
  return response;
});
