import * as yup from "yup";

export const loginValidationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Username is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});
export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});
export const sendEmailSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});
export const resetPasswordSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirm: yup
    .string()
    .min(8, "Confirm Password should be of minimum 8 characters length")
    .oneOf([yup.ref("password"), null], "Confirm Password must match")
    .required("Confirm Password is required"),
});
export const createUserSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  phone: yup.string().required("Phone number is required"),
});
export const updateUserSchema = yup.object({
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  phone: yup.string().required("Phone number is required"),
});
export const updatePersonalInfo = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: yup.string().required("Firstname is required"),
  lastName: yup.string().required("Lastname is required"),
  phone: yup.string().required("Phone number is required"),
});

export const changePasswordSchema = yup.object({
  oldPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  newPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirmNewPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const createClientSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup.string().required("Name is required"),
  phone: yup.string().required("Phone number is required"),
});
export const createProjectSchema = yup.object({
  name: yup.string().required("Name is required"),
  client: yup.string().required("Client is required"),
});
export const createForm = yup.object({
  name: yup.string().required("Name is required"),
});

export const createRequest = yup.object({
  name: yup.string().required("Name is required"),
  client: yup.string().required("Client is required"),
  phone: yup.string().required("Phone is required"),
  message: yup.string().required("Message is required"),
});
