import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Login from "../../pages/login";
import Error from "../../pages/Error";
import Dashboard from "../../pages/dashboard";
import Redirect from "../../pages/redirect";

//Requests
import Requests from "../../pages/requests";
import SingleRequests from "../../pages/requests/[id]";

//Forms

import Forms from "../../pages/forms";
import EditForms from "../../pages/forms/edit/[id]";
import FillForms from "../../pages/forms/fill/[id]";
import CreateForms from "../../pages/forms/create";

//account
import Account from "../../pages/account/index";
import Security from "../../pages/account/security";
import Logout from "../../pages/logout";

import Layout from "../Layout";
import AuthProvider from "../../providers/AuthProvider";
import { useSelector } from "react-redux";

export default function index() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {isAuthenticated ? (
            <Route>
              <Route
                path="/dashboard"
                element={
                  <Layout>
                    <Dashboard />
                  </Layout>
                }
              />
              <Route
                path="/requests"
                element={
                  <Layout>
                    <Requests />
                  </Layout>
                }
              />
              <Route
                path="/requests/:id"
                element={
                  <Layout>
                    <SingleRequests />
                  </Layout>
                }
              />
              <Route
                path="/forms"
                element={
                  <Layout>
                    <Forms />
                  </Layout>
                }
              />
              <Route
                path="/forms/edit/:id"
                element={
                  <Layout>
                    <EditForms />
                  </Layout>
                }
              />
              <Route path="/c/:id" element={<FillForms />} />
              <Route
                path="/forms/create"
                element={
                  <Layout>
                    <CreateForms />
                  </Layout>
                }
              />
              <Route
                path="/account"
                element={
                  <Layout>
                    <Account />
                  </Layout>
                }
              />
              <Route
                path="/account/security"
                element={
                  <Layout>
                    <Security />
                  </Layout>
                }
              />
              <Route
                path="/logout"
                element={
                  <Layout>
                    <Logout />
                  </Layout>
                }
              />
            </Route>
          ) : (
            <Route>
              <Route path="/login" element={<Login />} />
            </Route>
          )}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
