import React, { useEffect, useState } from "react";
import Loader from "../components/ui/Loader";
import { useDispatch } from "react-redux";
import { V1 } from "../utils/api";
import { useNavigate } from "react-router-dom";
export default function Logout() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    V1.delete("/auth/logout").then(() => {
      dispatch({
        type: "LOG_OUT",
      });
      navigate("/login");
    });
  }, []);

  return (
    <>
      <Loader />
    </>
  );
}
