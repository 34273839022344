import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { Link } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

function saveSvg(svgEl, name) {
  svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
  var svgData = svgEl.outerHTML;
  var preface = '<?xml version="1.0" standalone="no"?>\r\n';
  var svgBlob = new Blob([preface, svgData], {
    type: "image/svg+xml;charset=utf-8",
  });
  var svgUrl = URL.createObjectURL(svgBlob);
  var downloadLink = document.createElement("a");
  downloadLink.href = svgUrl;
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export default function TableComp({ rows, deleteRow }) {
  const handleDownload = (item) => {
    var svg = document.getElementById(`${item._id}`);
    if (svg) {
      saveSvg(svg, `QR_${item.name}.svg`);
    }
  };
  return (
    <TableContainer component={Paper}>
      {rows.length === 0 ? (
        <p style={{ textAlign: "center", width: "100%" }}>Keine Ergebnisse</p>
      ) : (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Formularname</TableCell>
              <TableCell align="right">Kunden</TableCell>
              <TableCell align="right">Einreichungen</TableCell>
              <TableCell align="right">QR Scans</TableCell>
              <TableCell align="right">Aktionen</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row) => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  {row?.clients.map((item, index) => {
                    if (row?.clients?.length - 1 === index) {
                      return `${item}`;
                    }
                    return `${item}, `;
                  })}
                </TableCell>
                <TableCell align="right">{row.scans}</TableCell>
                <TableCell align="right">{row.submissions}</TableCell>
                <TableCell align="right">
                  <div style={{ display: "none" }}>
                    <QRCodeSVG
                      value={`https://qr.paloka-ag.ch/c/${row._id}`}
                      size={128}
                      bgColor={"#ffffff"}
                      fgColor={"#000000"}
                      level={"M"}
                      includeMargin={false}
                      id={row._id}
                    />
                  </div>

                  <IconButton
                    target="_blank"
                    component="a"
                    href={`/c/${row._id}`}
                  >
                    <InsertLinkIcon />
                  </IconButton>
                  <IconButton onClick={(e) => handleDownload(row)}>
                    <CloudDownloadOutlinedIcon />
                  </IconButton>
                  <IconButton component={Link} to={`/forms/edit/${row._id}`}>
                    <EditOutlinedIcon />
                  </IconButton>
                  <IconButton onClick={() => deleteRow(row._id)}>
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
