import styled from "@emotion/styled";
import LinearProgress from "@mui/material/LinearProgress";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
`;

export default function Loader({ loading = false }) {
  if (!loading) return null;
  return (
    <Wrapper>
      <LinearProgress />
    </Wrapper>
  );
}
