import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { V1 } from "../utils/api";
import Loader from "../components/Loader";
import { useNavigate } from "react-router";

const Wrapper = styled.div``;

export default function Login({ children }) {
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    V1.get("/auth/account")
      .then((res) => {
        dispatch({
          type: "RE_AUTHENTICATE",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "AUTH_ERROR",
        });
        navigate("/login");
      });
  }, []);

  return <Wrapper>{loading ? <Loader /> : children}</Wrapper>;
}
