import React from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 100;
`;
export default function index() {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  );
}
