import { combineReducers } from "redux";
import randomReducer from "./randomReducer";
import authReducer from "./authReducer";

// Combine all reducers.
const appReducer = combineReducers({
  random: randomReducer,
  auth: authReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};
export default rootReducer;
