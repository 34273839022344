import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinkIcon from "@mui/icons-material/Link";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import SignalWifi0BarOutlinedIcon from "@mui/icons-material/SignalWifi0BarOutlined";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Paper
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ marginTop: 30, padding: 30 }}
      {...other}
    >
      {value === index && children}
    </Paper>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper style={{ overflow: "hidden" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab icon={<LinkIcon />} label="Url" {...a11yProps(0)} />
          <Tab
            icon={<ImportContactsIcon />}
            label="Contact"
            {...a11yProps(1)}
          />
          <Tab
            icon={<PictureAsPdfOutlinedIcon />}
            label="PDF"
            disabled
            {...a11yProps(2)}
          />
          <Tab
            icon={<EmailOutlinedIcon />}
            label="Email"
            disabled
            {...a11yProps(3)}
          />
          <Tab
            icon={<LocalPhoneOutlinedIcon />}
            label="Phone"
            disabled
            {...a11yProps(4)}
          />
          <Tab
            icon={<SmsOutlinedIcon />}
            label="SMS"
            disabled
            {...a11yProps(5)}
          />
          <Tab
            icon={<SignalWifi0BarOutlinedIcon />}
            label="WIFI"
            disabled
            {...a11yProps(5)}
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        Item One
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
