import React from "react";
import styled from "@emotion/styled";
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  min-height: 80px;
  .title {
    h2 {
      font-size: 24px;
      margin: 0;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export default function Dashboard({ title = "", children }) {
  return (
    <Wrapper>
      <div className="title">
        <h2>{title}</h2>
      </div>
      <div className="actions">{children}</div>
    </Wrapper>
  );
}
